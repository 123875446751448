
@font-face {
    font-family: 'Work Sans';
    font-weight: 400;
    font-style: normal;
    src: url('./fonts/WorkSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Work Sans';
    font-weight: 500;
    font-style: medium;
    src: url('./fonts/WorkSans-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Work Sans';
    font-weight: 600;
    font-style: semibold;
    src: url('./fonts/WorkSans-SemiBold.ttf') format('truetype');
}


@font-face {
    font-family: 'Work Sans';
    font-weight: 700;
    font-style: bold;
    src: url('./fonts/WorkSans-Bold.ttf') format('truetype');
}


@font-face {
    font-family: 'SF Pro Display';
    font-weight: 400;
    font-style: normal;
    src: url('./fonts/SF-Pro-Display-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'SF Pro Display';
    font-weight: 500;
    font-style: medium;
    src: url('./fonts/SF-Pro-Display-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'SF Pro Display';
    font-weight: 600;
    font-style: semibold;
    src: url('./fonts/SF-Pro-Display-Semibold.ttf') format('truetype');
}


@font-face {
    font-family: 'SF Pro Display';
    font-weight: 700;
    font-style: bold;
    src: url('./fonts/SF-Pro-Display-Bold.ttf') format('truetype');
}

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    user-select: none;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
}

body {
    font-family: 'SF Pro Display', sans-serif;
    cursor: default;
}

.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    height: 70px;
    min-height: 60px;
    max-height: 8vh;
}

.button-shadow-connect {
    box-shadow:
        10px 1px 19.4px 0px rgba(0, 0, 0, 0.16) inset,
        -10px -10px 18.1px 0px rgba(255, 255, 255, 0.7) inset,
        7px 3px 2.6px 0px rgba(228, 226, 221, 1) inset,
        1px 0px 5.6px 0px rgba(0, 0, 0, 0.05);


    border: 1px solid rgba(0, 0, 0, 0.12);
}

.button-shadow {
    box-shadow: inset 7px 3px 2.6px rgba(0, 0, 0, 0.12),
        inset -10px -10px 18px rgba(255, 255, 255, 0.7),
        inset 10px 1px 19px rgba(0, 0, 0, 0.16),
        inset 1px 0px 5.6px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.12);
}

.button-shadow-normal {
    box-shadow: inset 7px 3px 2.6px #242424,
        inset -3px -1px 18px #303030,
        inset 10px 1px 19.4px rgba(47, 47, 47, 0.16),
        inset 1px 0px 2.6px #000000;
    border: 1px solid rgba(0, 0, 0, 0.12);
}

.button-shadow-dark {
    box-shadow: inset 7px 3px 2.6px #242424,
        inset -3px -1px 18px #303030,
        inset 10px 1px 19.4px #282828,
        inset 1px 0px 2.6px rgba(0, 0, 0, 0.066);
    border: 1px solid rgba(0, 0, 0, 0.12);
}


body {
    font-family: 'SF Pro Display', sans-serif;
}


.switch-label {
    width: 116px;
    height: 57px;
    position: relative;
    display: block;
    border-radius: 40px;
    background-color: #ebebeb;
    box-shadow: inset 2px 2px 7px rgba(0, 0, 0, 0.2), inset -5px -5px 10px rgba(255, 255, 255, 1);
    transition: 0.25s ease-in-out;
    cursor: pointer;
}

.switch-label:after {
    content: "";
    width: 43px;
    height: 43px;
    position: absolute;
    top: 7px;
    left: 7px;
    background-color: white;
    border-radius: 32px;
    box-shadow: inset -2px -2px 2px rgba(255, 255, 255, 0.25),
        2px 2px 10px rgba(0, 0, 0, 0.3);
    transition: 0.25s ease-in-out;
}

.switch-input:checked+.switch-label:after {
    left: 109px;
    transform: translateX(-100%);
    background-color: #E15511;
}

.switch-label-sm {
    width: 60px;
    height: 30px;
    border-radius: 30px;
}

.switch-label-sm:after {
    width: 24px;
    height: 24px;
    top: 3px;
    left: 4px;
    position: absolute;
    border-radius: 24px;
}

.switch-input {
    width: 0;
    height: 0;
    visibility: hidden;
}

.switch-input-sm:checked+.switch-label-sm:after {
    left: 54px;
    transform: translateX(-100%);
    background-color: #E15511;
}

.switch-label:active:after {
    width: 60px;
}

.switch-label-sm:active:after {
    width: 48px;
}

.chat-content-background {
    backdrop-filter: blur(8px);
    border-radius: 0 48px 48px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.chat-content-background-sm {
    backdrop-filter: blur(8px);
    border-radius: 38px;
}

.section-chat-container {
    border-radius: 0 48px 48px 0;
}

.section-chat-container-sm {
    border-radius: 38px;
}

.chat-list-background {
    backdrop-filter: blur(8px);
    border-radius: 48px 0 0 48px;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);

}

.chat-search-box {
    box-shadow: inset 2px 2px 6px rgba(0, 0, 0, 0.3),
        0px 1px 1px rgba(255, 255, 255, 0.5);

    background: rgba(0, 0, 0, 0.3);
}

.chat-profile-selected {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 24px;
}

@keyframes scale {
    0% {
        transform: scale(0.3);
    }

    100% {
        transform: scale(1);
    }
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

.scrollbar-hide {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.join-button {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 28px 32px;
    border-radius: 32px;
}

.join-button:after {

    content: "";
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 98%;
    height: 94%;
    background: radial-gradient(circle at center bottom,
            #F38C59, #CB4909, #B5420A);
    border-radius: 32px;

}

.join-button:before {
    content: "";
    background-image: conic-gradient(white 0deg, transparent 90deg, transparent 270deg, white 360deg);
    width: 380%;
    height: 380%;
    position: absolute;
    animation: joinLineRotate 1.5s linear infinite;
    animation-delay: 2s;
}

@keyframes joinLineRotate {

    0% {
        transform: rotate(0deg);
        opacity: 0.2;
    }

    20% {
        transform: rotate(45deg);
        opacity: 1;
    }

    35% {
        transform: rotate(180deg);
        opacity: 0.7;
    }

    65% {
        opacity: 0.4;
    }

    80% {
        transform: rotate(360deg);
        opacity: 0.2;
    }



    100% {
        transform: rotate(360deg);
        opacity: 0;
    }
}


.arrow1 {
    animation: fadeInOut 1.5s infinite;
    animation: slideAnimation 1.5s infinite;
}

.arrow2 {
    animation: fadeInOut 1.5s infinite;
    animation: slideAnimation2 1.5s infinite;
}


@keyframes slideAnimation {
    0% {
        opacity: 0;
        transform: translateX(20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }

}

@keyframes slideAnimation2 {
    0% {
        opacity: 0;
        transform: translateX(10px);
    }

    0%,
    50% {
        opacity: 0;
        transform: translateX(10px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }

}

.arrow-next-1 {
    animation: arrowNext1 1s infinite;
}

.arrow-next-2 {
    animation: arrowNext2 1s infinite;
}

@keyframes arrowNext1 {
    0% {
        opacity: 0;
        transform: translateX(0px);
    }

    100% {
        opacity: 1;
        transform: translateX(10px);
    }

}

@keyframes arrowNext2 {
    0% {
        opacity: 0;
        transform: translateX(5px);
    }

    0%,
    50% {
        opacity: 0;
        transform: translateX(10px);
    }

    100% {
        opacity: 1;
        transform: translateX(15px);
    }

}

@keyframes fadeInOut {

    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


@keyframes slideOutLeft {
    from {
        transform: translateX(0);
        opacity: 1;
    }

    to {
        transform: translateX(-100%);
        opacity: 0;
    }
}

@keyframes slideInLeft {
    from {
        transform: translateX(100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.slide-out-left {
    animation: slideOutLeft 0.5s forwards;
}

.slide-in-left {
    animation: slideInLeft 0.5s forwards;
}


@keyframes slideOutRight {
    from {
        transform: translateX(0);
        opacity: 1;
    }

    to {
        transform: translateX(100%);
        opacity: 0;
    }
}

@keyframes slideInRight {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.slide-out-right {
    animation: slideOutRight 0.5s forwards;
}

.slide-in-right {
    animation: slideInRight 0.5s forwards;
}

.popup-grow-content {
    animation: grow 0.5s ease-in-out forwards;
    transform-origin: center;
    width: 100%;
    height: 100%;
}

@keyframes grow {
    from {
        transform: translate(-50%, -50%) scale(0);
    }

    to {
        transform: translate(-50%, -50%) scale(1);
        width: 100vw;
        height: 100vh;
        top: 50%;
        left: 50%;
    }
}

.popup-closing {
    animation: shrink 0.5s ease-in-out forwards;
}

@keyframes shrink {
    from {
        transform: translate(-50%, -50%) scale(1);
        width: 100vw;
        height: 100vh;
        top: 50%;
        left: 50%;
    }

    to {
        transform: translate(-50%, -50%) scale(0);
    }
}

.animated-paragraph-center-to-left {
    animation: serviceParacgraphSlide 0.5s ease-in-out forwards;
}

@keyframes serviceParacgraphSlide {
    0% {
        transform: translateX(50%);
        opacity: 0;
    }

    50% {
        opacity: 0.2;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.service-popup-p {
    letter-spacing: -0.011em;
}

.shadow-left-bottom {
    box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.2);
}

.nav-underline {
    position: relative;
}

.nav-underline::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -2px;
    width: 0;
    height: 2px;
    background-color: #E15511;
    transform: translateX(-50%);
    transform-origin: center;
    transition: width 0.3s ease;
}

.nav-underline.is-active::after,
.nav-underline:hover::after {
    width: 100%;
}

.nav-underline:hover,
.nav-underline.is-active {
    color: #E15511;
}

/* SECTION 1 */
.s1-margin-top {
    margin-top: 10%;
}

.line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}


/* JOIN JOIN JOIN POPUP */
@keyframes slideIn {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes slideOut {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(100%);
    }
}

.popup-enter {
    animation: slideIn 0.3s forwards;
}

.popup-exit {
    animation: slideOut 0.3s forwards;
}


.gif-width {
    width: clamp(320px, calc(55vw), 700px);
}

@media (max-aspect-ratio: 1/1) {
    .gif-width {
        width: clamp(320px, calc(100vw), 700px);
    }
}

.bbb{
    border-radius: 100px;
}

.gravisun-texture {
    width: clamp(126px, calc(100vw * 0.1020833333), 216px);
}