.join-us-loading-container-padding {
  padding-inline: clamp(8px, calc((100 / 1920) * 120vw), 100px);
  margin-top: calc(4px + clamp(8px, calc((90 / 1920) * 100vw), 120px));

}

.join-us-loading-radius {
  border-radius: clamp(32px, calc(0.05416666667 * 100vw), 160px);
  padding-inline: 24px;
  padding-top: clamp(24px, calc(0.07447916667 * 200vw), 220px);
  padding-bottom: clamp(24px, calc(0.02708333333 * 200vw), 100px);
}

.join-us-loading-progress-line-margin-top {
  margin-top: clamp(24px, calc(0.046875 * 200vw), 160px);
}


.join-us-form-field-text-size {
  font-size: clamp(14px, calc(0.04148471616 * 70vw), 38px);
  padding-left: clamp(10px, calc(0.05208333333 * 40vw), 50px);
}

.join-us-form-field-text-size::placeholder {
  font-size: clamp(14px, calc(0.04148471616 * 70vw), 38px);
  color: #292929;
  opacity: 50%;
}

.join-us-form-field-upload-text-size {
  font-size: clamp(14px, calc(0.03056768559 * 70vw), 28px);
}


.join-us-iphone-ratio-h-bigger {
  width: 100%;
  margin-top: 12px;
}

.join-us-name-field-image-size {
  width: clamp(54px, calc(0.05625 * 80vw), 108px);
  height: clamp(54px, calc(0.05625 * 80vw), 108px);
  margin-left: clamp(10px, calc((0.05208333333 * 40vw)), 50px);
}

.join-us-gravisun-text-size {

  width: clamp(83px, calc(0.08645833333 * 65vw), 166px);
  margin-left: clamp(24px, calc(0.0390625 * 65vw), 75px);
  margin-bottom: clamp(12px, calc(0.01875 * 65vw), 35px);
}

/* MD */
@media (min-width: 540px) {
  .join-us-iphone-ratio-h-bigger {
    width: 75%;
    margin-top: 12px;
  }

  .join-us-loading-container-padding {
    padding-inline: clamp(8px, calc((100 / 1920) * 100vw), 100px);
    margin-top: calc(4px + clamp(8px, calc((90 / 1920) * 85vw), 120px));

  }

  .join-us-loading-radius {
    border-radius: clamp(32px, calc(0.05416666667 * 110vw), 160px);
    padding-inline: 54px;
    padding-top: clamp(24px, calc(0.07447916667 * 140vw), 220px);
    padding-bottom: clamp(24px, calc(0.02708333333 * 100vw), 100px);
  }

  .join-us-loading-progress-line-margin-top {
    margin-top: clamp(24px, calc(0.046875 * 110vw), 160px);
  }

}

/* nav-sm */
@media (min-width: 855px) {


  .join-us-loading-container-padding {
    padding-inline: clamp(8px, calc((96 / 1920) * 90vw), 100px);
    margin-top: calc(4px + clamp(8px, calc((100 / 1920) * 70vw), 120px));

  }

  .join-us-loading-radius {
    border-radius: clamp(32px, calc(0.05416666667 * 120vw), 160px);
    padding-inline: 32px;
    padding-top: clamp(24px, calc(0.07447916667 * 100vw), 220px);
    padding-bottom: clamp(24px, calc(0.02708333333 * 120vw), 100px);
  }

  .join-us-loading-progress-line-margin-top {
    margin-top: clamp(24px, calc(0.046875 * 120vw), 160px);
  }

  .join-us-iphone-ratio-h-bigger {
    width: 69%;
  }

  .join-us-form-field-text-size {
    font-size: clamp(14px, calc(0.04148471616 * 50vw), 38px);
    padding-left: clamp(10px, calc(0.05208333333 * 30vw), 50px);
  }

  .join-us-form-field-text-size::placeholder {
    font-size: clamp(14px, calc(0.04148471616 * 50vw), 38px);
    color: #292929;
    opacity: 45%;
  }

  .join-us-form-field-upload-text-size {
    font-size: clamp(14px, calc(0.03056768559 * 34vw), 28px);
  }

}

/* lg */
@media (min-width: 1024px) {
  .join-us-iphone-ratio-h-bigger {
    width: 65%;
  }

  .join-us-loading-container-padding {
    padding-inline: clamp(8px, calc((100 / 1920) * 75vw), 100px);
    margin-top: calc(4px + clamp(8px, calc((90 / 1920) * 65vw), 120px));

  }

  .join-us-loading-radius {
    border-radius: clamp(32px, calc(0.05416666667 * 110vw), 160px);
    padding-inline: 54px;
    padding-top: clamp(24px, calc(0.07447916667 * 120vw), 220px);
    padding-bottom: clamp(24px, calc(0.02708333333 * 130vw), 100px);
  }


}

/* c-lg */
@media (min-width: 1280px) {

  .join-us-loading-container-padding {
    padding-inline: clamp(8px, calc((94 / 1920) * 50vw), 100px);
    margin-top: calc(4px + clamp(8px, calc((94 / 1920) * 40vw), 120px));

  }

  .join-us-loading-radius {
    border-radius: clamp(32px, calc(0.05416666667 * 65vw), 160px);
    padding-inline: 32px;
    padding-top: clamp(24px, calc(0.07447916667 * 80vw), 220px);
    padding-bottom: clamp(24px, calc(0.02708333333 * 120vw), 100px);
  }

  .join-us-loading-progress-line-margin-top {
    margin-top: clamp(24px, calc(0.046875 * 70vw), 160px);
  }

  .join-us-iphone-ratio-h-bigger {
    width: 45%;
    margin-top: -12px;
  }

  .join-us-form-field-text-size {
    font-size: clamp(14px, calc(0.04148471616 * 30vw), 38px);
    padding-left: clamp(10px, calc(0.05208333333 * 40vw), 50px);
  }

  .join-us-form-field-text-size::placeholder {
    font-size: clamp(14px, calc(0.04148471616 * 30vw), 38px);
    color: #292929;
    opacity: 50%;
  }

  .join-us-name-field-image-size {
    width: clamp(54px, calc(0.05625 * 55vw), 108px);
    height: clamp(54px, calc(0.05625 * 55vw), 108px);
    margin-left: clamp(10px, calc((0.05208333333 * 24vw)), 50px);
  }
}


/* c-2lg */
@media (min-width: 1536px) {

  .join-us-loading-container-padding {
    padding-inline: clamp(8px, calc((104 / 1920) * 44vw), 100px);
    margin-top: calc(4px + clamp(8px, calc((100 / 1920) * 35vw), 120px));

  }

  .join-us-loading-radius {
    border-radius: clamp(32px, calc(0.05416666667 * 60vw), 160px);
    padding-inline: 32px;
    padding-top: clamp(24px, calc(0.07447916667 * 80vw), 220px);
    padding-bottom: clamp(24px, calc(0.02708333333 * 120vw), 100px);
  }

  .join-us-loading-progress-line-margin-top {
    margin-top: clamp(24px, calc(0.046875 * 70vw), 160px);
  }

  .join-us-iphone-ratio-h-bigger {
    width: 37%;
    margin-top: -12px;
  }
}


/* c-xl */
@media (min-width: 1919px) {

  .join-us-loading-container-padding {
    padding-inline: clamp(8px, calc((104 / 1920) * 45vw), 100px);
    margin-top: calc(4px + clamp(8px, calc((104 / 1920) * 35vw), 120px));

  }

  .join-us-loading-radius {
    border-radius: clamp(32px, calc(0.05416666667 * 55vw), 160px);
    padding-inline: 32px;
    padding-top: clamp(24px, calc(0.07447916667 * 80vw), 220px);
    padding-bottom: clamp(24px, calc(0.02708333333 * 120vw), 100px);
  }

  .join-us-loading-progress-line-margin-top {
    margin-top: clamp(24px, calc(0.046875 * 70vw), 160px);
  }

  .join-us-iphone-ratio-h-bigger {
    width: 36%;
    margin-top: -12px;
  }
}


/* 3xl */
@media (min-width: 2560px) {

  .join-us-loading-container-padding {
    padding-inline: clamp(8px, calc((104 / 1920) * 43vw), 100px);
    margin-top: calc(4px + clamp(8px, calc((104 / 1920) * 36vw), 120px));

  }

  .join-us-loading-radius {
    border-radius: clamp(32px, calc(0.05416666667 * 60vw), 160px);
    padding-inline: 32px;
    padding-top: clamp(24px, calc(0.07447916667 * 80vw), 220px);
    padding-bottom: clamp(24px, calc(0.02708333333 * 120vw), 100px);
  }

  .join-us-loading-progress-line-margin-top {
    margin-top: clamp(24px, calc(0.046875 * 70vw), 160px);
  }

  .join-us-iphone-ratio-h-bigger {
    width: 37%;
    margin-top: -12px;
  }

  .join-us-form-field-text-size {
    font-size: clamp(14px, calc(0.04148471616 * 22vw), 38px);
    padding-left: clamp(10px, calc(0.05208333333 * 22vw), 50px);
  }

  .join-us-form-field-text-size::placeholder {
    font-size: clamp(14px, calc(0.04148471616 * 22vw), 38px);
    color: #292929;
    opacity: 50%;
  }

  .join-us-form-field-upload-text-size {
    font-size: clamp(14px, calc(0.03056768559 * 22vw), 28px);
  }
}



/* 4k */
@media (min-width: 3840px) {

  .join-us-iphone-ratio-h-bigger {
    width: 33%;
    margin-top: -12px;
  }

}

@media (max-width: 1280px) {
  .click-to-dismiss-visibility {
    display: none;
  }
}

@media (max-aspect-ratio: 1/1) {
  .click-to-dismiss-visibility {
    display: none;
  }
}