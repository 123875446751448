html {
    scroll-behavior: smooth;
    overflow-y: scroll;
    scroll-snap-stop: always;
}

.cond-mandatory {
    /* scroll-snap-type: y mandatory;
    overflow-y: scroll; */
}

.cond-proximity {
    /* scroll-snap-type: y proximity;
    overflow-y: scroll; */
}

.sec {
    scroll-snap-align: center;
}


.no-scroll {
    overflow: hidden;
}