.section1 {
    padding-top: calc(env(safe-area-inset-top) + 60px);
    padding-bottom: calc(env(safe-area-inset-bottom));
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
}

.section2 {
    padding-top: calc(env(safe-area-inset-top) + 60px);
    padding-bottom: calc(env(safe-area-inset-bottom) + 60px);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
}

.section3 {
    padding-top: calc(env(safe-area-inset-top) + 60px);
    padding-bottom: calc(env(safe-area-inset-bottom) + 60px);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
}

.section4 {
    padding-top: calc(env(safe-area-inset-top));
    padding-bottom: calc(env(safe-area-inset-bottom) + 60px);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
}

.section5 {
    padding-top: calc(env(safe-area-inset-top) + 60px);
    padding-bottom: calc(env(safe-area-inset-bottom));
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
}

.section6 {
    padding-top: calc(env(safe-area-inset-top) + 60px);
    padding-bottom: calc(env(safe-area-inset-bottom));
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
}

.section7 {
    padding-top: calc(env(safe-area-inset-top) + 60px);
    padding-bottom: calc(env(safe-area-inset-bottom));
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
}

.section8 {
    padding-top: calc(env(safe-area-inset-top) + 60px);
    padding-bottom: calc(env(safe-area-inset-bottom) + 60px);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
}

.macImage {
    margin-left: calc(-1 * env(safe-area-inset-left, 0px));
}

.joinus-pb {
    padding-bottom: calc(env(safe-area-inset-bottom) + 92px);
}

@media (min-width: 855px) {
    .joinus-pb {
        padding-bottom: calc(env(safe-area-inset-bottom) + 12px);
    }
}

.service-minimize-mt {
    margin-top: 48px;
}

.service-next-mb {
    margin-bottom: 48px;
}

.service-description-next-pb {
    padding-bottom: calc(env(safe-area-inset-bottom) + 10px);
}