.image-animate {
    animation: moveUp 1.2s ease-out forwards;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
}

.image-animate2 {
    animation: moveUp2 1.2s ease-out forwards;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
}

@keyframes moveUp {
    0% {
        transform: translateY(80vh);
    }

    100% {
        transform: translateY(0);
    }
}


@keyframes moveUp2 {
    0% {
        transform: translateY(100vh);
    }

    100% {
        transform: translateY(0);
    }
}


.rotate-animation {
    animation: rotateAnimation 1s ease-out 0.3s forwards;
}

@keyframes rotateAnimation {
    from {
        transform: rotate(-4deg);
    }

    to {
        transform: rotate(-6.02deg);
    }
}

.rotate-animation2 {
    animation: rotateAnimation2 1s ease-out 0.3s forwards;
}

@keyframes rotateAnimation2 {
    from {
        transform: rotate(3deg);
    }

    to {
        transform: rotate(4.62deg);
    }
}