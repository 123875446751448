@keyframes fadeInDown {
    0% {
        transform: translateY(-40%) scale(1.5);
        opacity: 0;
    }

    100% {
        transform: translateY(25%) scale(1);
        opacity: 1;
    }
}

@keyframes fadeOutDown {
    0% {
        transform: translateY(25%) scale(1);
        opacity: 1;
    }

    100% {
        transform: translateY(50%) scale(0.5);
        opacity: 0;
    }
}

.fade-in-down {
    animation: fadeInDown 0.5s ease-in-out forwards;
}

.fade-out-down {
    animation: fadeOutDown 0.5s ease-in-out forwards;
}