.notif-item {
    opacity: 0;
    transform: scale(0);
    transition: opacity 1.2s ease-in-out, transform 0.5s ease-in-out, margin 0.5s ease-in-out, max-height 1.2s ease-in-out;
    margin-top: 0;
    max-height: 0;
    overflow: hidden;
}

.notif-item.visible {
    opacity: 1;
    transform: scale(1);
    margin-top: 10px;
    max-height: 500px;
}

.c-hidden {
    opacity: 0.01;
}

.c-visible {
    opacity: 1;
}

.notif-item-vetical-padding {
    padding-top: clamp(18px, calc(75vw * 0.01105729167), 100vw);
    padding-bottom: clamp(18px, calc((75vw * 0.01105729167)), 100vw);
    padding-left: clamp(25px, calc(85vw * 0.01327083333), 100vw);
    padding-right: clamp(25px, calc((75vw * 0.01510416667)), 100vw);
}

.notif-background {
    box-shadow: inset 3px 3px 2px rgba(255, 255, 255, 0.1), inset -2px 0px 2px rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(15px);
    border: 1px solid rgba(255, 255, 255, 0.2);
}