.service-grid-width {
  width: 90%;
}

.join-input {
  border-color: transparent;
  outline: none;
  border-radius: 16px;
  background-color: transparent;
  padding: 4px 10px;
}

.join-input-container {

  background-color: white;
  border-radius: 16px;
  padding: 10px 10px;
}


/* nav-sm */
@media (min-width: 855px) {
  .service-grid-width {
    width: 90%;
  }

  .join-input {
    border-color: transparent;
    outline: none;
    border-radius: 20px;
    background-color: transparent;
    padding: 4px 10px;
  }

  .join-input-container {

    background-color: white;
    border-radius: 20px;
    padding: 10px 10px;
  }


}

/* c-lg */
@media (min-width: 1280px) {
  .service-grid-width {
    width: 85%;
  }

  .join-input-container {
    background-color: white;
    border-radius: 14px;
    padding: 10px 10px;
  }
}

/* c-2lg */
@media (min-width: 1536px) {
  .service-grid-width {
    width: 75%;
  }

  .join-input-container {
    background-color: white;
    border-radius: 16px;
    padding: 10px 10px;
  }

}

/* c-xl */
@media (min-width: 1919px) {
  .service-grid-width {
    width: 70%;
  }

  .join-input {
    border-color: transparent;
    outline: none;
    border-radius: 18px;
    background-color: transparent;
    padding: 4px 10px;
  }

  .join-input-container {

    background-color: white;
    border-radius: 18px;
    padding: 10px 10px;
  }


}

/* 3xl */
@media (min-width: 2560px) {
  .service-grid-width {
    width: 65%;
  }

  .join-input {
    border-color: transparent;
    outline: none;
    border-radius: 24px;
    background-color: transparent;
    padding: 4px 10px;
  }

  .join-input-container {
    background-color: white;
    border-radius: 24px;
    padding: 10px 10px;
  }


}

/* 4k */
@media (min-width: 3840px) {

  .join-input-container {
    background-color: white;
    border-radius: 28px;
    padding: 10px 10px;
  }

}

.notifs-title-size {
  font-size: clamp(30px, calc(0.03125 * 73vw), 74px);
}

.notifs-subtitle-size {
  font-size: clamp(16px, calc(0.015625 * 88vw), 42px);
}

.notif-title-font-size {
  font-size: clamp(18px, calc(0.0109375 * 98vw), 100vw);
}

.notif-title-description-size {
  font-size: clamp(14px, calc(0.009375 * 98vw), 100vw);
}

.notif-item-image-size {
  width: clamp(62px, calc(100vw * 0.03870833333), 100vw);
  height: clamp(59px, calc(100vw * 0.035390625), 100vw);
  margin-right: clamp(11px, calc(100vw * 0.00553125), 100vw);
}

@media (max-aspect-ratio: 1/0.9) {

  .notifs-title-size {
    font-size: clamp(30px, calc(0.03125 * 110vw), 74px);
  }
  
  .notifs-subtitle-size {
    font-size: clamp(16px, calc(0.015625 * 140vw), 42px);
  }

  .notif-title-font-size {
    font-size: clamp(18px, calc(0.0109375 * 160vw), 100vw);
  }
  
  .notif-title-description-size {
    font-size: clamp(12px, calc(0.009375 * 175vw), 100vw);
  }

  .notif-item-image-size {
    width: clamp(62px, calc(160vw * 0.03870833333), 100vw);
    height: clamp(59px, calc(160vw * 0.035390625), 100vw);
    margin-right: clamp(11px, calc(160vw * 0.00553125), 100vw);
  }
}

.join-up-title-padding {
  padding-top: clamp(32px, calc(100% * 0.05729166667), 140px);
  padding-left: clamp(40px, calc(100% * 0.0875), 166px);
  padding-right: clamp(40px, calc(100% * 0.0875), 166px);
}

/* JOIN US FORM */

.user-name-field {
  width: 100%;
  aspect-ratio: 1 / 0.1790393013;

  min-width: 100%;
  max-width: 100%;

  min-height: calc(min-width / 0.1790393013);
  max-height: 184px;
}

.user-normal-field {
  width: 100%;
  aspect-ratio: 1 / 0.1179039301;

  min-width: 100%;
  max-width: 100%;

  min-height: calc(min-width / 0.1179039301);
  max-height: 120px;
}

.upload-file-field {
  width: 100%;
  aspect-ratio: 1 / 0.2183406114;
  min-height: 90px;
  max-height: 200px;
}

.upload-file-buttun {
  height: 100%;
  aspect-ratio: 182 / 200;
}


.join-us-form-field-text-size:focus {
  border: none;
  box-shadow: none;
  outline: none;
}

.file-name-max-char {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}