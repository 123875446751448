.sun {
    animation: shine 4s ease-in-out infinite;
    border-radius: 50%;
}

.sun-ratio {
    width: 30vw;
    height: 30vw;
    position: absolute;
    left: -5vw;
    bottom: -12vw;
}

/* nav-sm */
@media (min-width: 855px) {
    .sun-ratio {
        width: 33vw;
        height: 33vw;
        position: absolute;
        left: -7vw;
        bottom: -13vw;
    }
}

@media (min-width: 1920px) {
    .sun-ratio {
        width: 30vw;
        height: 30vw;
        position: absolute;
        left: -5vw;
        bottom: -12vw;
    }
}

.sun-sm {
    height: calc(120vw);
    width: 120vw;
    bottom: -93vw;
    left: 50%;
    transform: translateX(-50%);
}

/* Section 4 */
.sun-sm-bottom {
    height: calc(120vw);
    width: 120vw;
    top: calc(-0.97 * 120vw);
    left: -10%;
    position: absolute;
}

.sun-md-top-right {
    height: 19.5vw;
    width: 19.5vw;
    top: -7vw;
    right: -7vw;
    position: absolute;
}

.sun-md-top-right-large {
    height: 25vw;
    width: 25vw;
    top: -8vw;
    right: -9vw;
    position: absolute;
}

@keyframes shine {

    0% {
        box-shadow: -5px -1px 6px rgba(203, 73, 9, 1),
            -5px -1px 22px rgba(203, 73, 9, 1),
            -5px -1px 34px rgba(203, 73, 9, 1);
    }

    50% {
        box-shadow: -5px -1px 24px rgba(203, 73, 9, 1),
            -5px -1px 90px rgba(203, 73, 9, 1),
            -5px -1px 137px rgba(203, 73, 9, 1);
    }

    100% {
        box-shadow: -5px -1px 6px rgba(203, 73, 9, 1),
            -5px -1px 22px rgba(203, 73, 9, 1),
            -5px -1px 34px rgba(203, 73, 9, 1);
    }
}